import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { RouteComponentProps, useHistory } from "react-router";

import ConverterEngine from "./components/engine";
import myUtil, { IS_EMBED } from "../../helpers/cha-utils";
import { useState } from "react";
import { copyOutline, reloadOutline } from "ionicons/icons";
import { InlineMath } from "react-katex";

import "katex/dist/katex.min.css";
import classes from './index.module.css';
import BasicTextBox from "../../components/BasicTextBox";
import { Route } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
interface ConverterProps
  extends RouteComponentProps<{
    from: string;
    to: string;
  }> { }

let pageTitle = "Length Converter"

const Index: React.FC<ConverterProps> = ({ match }) => {

  const [decimalValue, setDecimalValue] = useState<number>(8);

  const fromItem = ConverterEngine.ConverterItems[match.params.from] ?? ConverterEngine.ConverterItems.m;
  const toItem = ConverterEngine.ConverterItems[match.params.to] ?? ConverterEngine.ConverterItems.mm;

  if (fromItem && toItem) pageTitle = fromItem.title + " to " + toItem.title;

  const resultValue = myUtil.prepareFloatingNumber((1 / fromItem.formula) * toItem.formula, decimalValue);
  const clearButtonHandler = () => {
    setControlItems(initialControlItems);
  };

  type controlItemsType = {
    [key: number]: {
      input: string;
      value: number;
      label: string;
      converter: ConverterEngine.ConverterItemType,
    }
  };

  const initialControlItems: controlItemsType = {
    0: {
      input: "1",
      value: 1,
      label: "From",
      converter: fromItem
    },
    1: {
      input: resultValue.toString(),
      value: resultValue,
      label: "To",
      converter: toItem
    }
  };

  const [controlItems, setControlItems] =
    useState<controlItemsType>(initialControlItems);

  const inputChangeHandler = (
    controlId: number,
    user_input: string,
    user_value: number
  ) => {
    const standardValue = user_value / controlItems[controlId].converter.formula;
    setControlItems((oldItems) => {
      return Object.entries(oldItems).map(([key, item]) => {
        // return item;
        if (+key === controlId) {
          item.input = user_input;
          item.value = user_value;
        } else {
          item.value = myUtil.prepareFloatingNumber(item.converter.formula * standardValue, decimalValue);
          item.input = myUtil.formatNumber(item.value, decimalValue);
        }
        return item;
      });
    });
  };

  const controlItemsJSX = Object.entries(controlItems).map(([key, item]) => (
    <IonCol size="12" sizeMd="6" key={item.converter.key}>
      <div className={classes.converterLabel}>{item.label}</div>
      <BasicTextBox
        controlId={+key}
        type="text"
        label={item.converter.title}
        subLabel={item.converter.key}
        short={item.converter.short}
        shortWidth={64}
        textSize={4}
        input={item.input}
        value={item.value}
        onIonInput={inputChangeHandler}
        decimalValue={decimalValue}
      />
    </IonCol>
  ));


  const updateAllItems = (updateItem: controlItemsType) => {
    console.log(updateItem);
    let controlIndex = 2;
    return Object.entries(ConverterEngine.ConverterItems).map(([key, item]) => {
      if (item.key === fromItem.key || item.key === toItem.key) return "";
      controlIndex++;
      const value = myUtil.prepareFloatingNumber((item.formula * updateItem[0].value) / updateItem[0].converter.formula, decimalValue);
      // console.log(updateItem[0].value + " / " + updateItem[0].converter.formula + " * " + item.formula + " = " + value);
      return (
        <IonCol key={item.key} size="12" sizeMd="6">
          <BasicTextBox
            controlId={controlIndex}
            type="text"
            label={item.title}
            subLabel={item.short + " - " + item.key}
            short={item.short}
            shortWidth={40}
            textSize={2}
            input={myUtil.formatNumber(value, decimalValue)}
            value={value}
            onIonInput={inputChangeHandler}
            readonly={true}
          />
        </IonCol>
      );
    }
    );
  }

  const otherControlItemsJSX = updateAllItems(controlItems);


  const formulaResultText = myUtil.formatNumber((1 / fromItem.formula) * toItem.formula, decimalValue);
  const formulaJSX = (
    <InlineMath math={`\\text{1 ${fromItem.short}} = \\text{${formulaResultText} ${toItem.short}}`}></InlineMath>
  );

  // Toast Functions
  const [present] = useIonToast();
  const showToast = (
    message: string,
    position: "top" | "middle" | "bottom",
    icon?: string
  ) => {
    present({
      message: message,
      duration: 1500,
      position: position,
      icon: icon,
    });
  };

  const copyText = controlItems[0].value + " " + controlItems[0].converter.short + " = " + controlItems[1].value + " " + controlItems[1].converter.short;

  const history = useHistory();
  if (!ConverterEngine.ConverterItems[match.params.from] || !ConverterEngine.ConverterItems[match.params.to]) {
    history.replace("/");
    return null;
  }

  return (
    <IonPage id="main-content" className={classes.page}>
      {!IS_EMBED && (
        <IonHeader className="ion-no-border">
          <IonToolbar className={classes.toolbar}>
            <IonButtons slot="start">
              <IonBackButton></IonBackButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent>
        <IonGrid fixed={true}>
          <IonRow>
            <IonCol>
              <div className={classes.titleSection}>
                <h1>{pageTitle}</h1>
                <div className={classes.formulaSection}>
                  {formulaJSX}
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonGrid className="layoutBorder">
                <IonRow className="ion-justify-align-center">
                  {controlItemsJSX}
                </IonRow>
                <IonRow className="ion-justify-content-end">
                  <IonCol className="ion-text-right">
                    <IonButton color="danger" onClick={clearButtonHandler} title="Reset">
                      <IonIcon icon={reloadOutline}></IonIcon>
                    </IonButton>{` `}
                    <IonButton
                      onClick={() => {
                        myUtil.copyToClipboard(copyText);
                        showToast(
                          copyText + " — copied to the clipboard!",
                          "top",
                          copyOutline
                        );
                      }}
                    >
                      <IonIcon icon={copyOutline}></IonIcon>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid fixed={true} >
          <IonRow className="ion-justify-align-center" >
            <IonCol>
              <IonGrid className="layoutBorder">
                <IonRow>
                  {otherControlItemsJSX}
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <Footer decimalValue={decimalValue}
        onRangeUpdated={(v) => setDecimalValue(+v)} />
    </IonPage>
  );
};


const ConvertFromPage: React.FC<ConverterProps> = ({ match }) => {
  const converterFromListJSX = Object.entries(ConverterEngine.ConverterItems).map(([key, item]) => (
    <IonItem routerLink={`${match.url}/${item.short}/`} key={key}>
      {item.title}
    </IonItem>
  ));
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Convert From</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList className="layoutBorder" lines="full">
          {converterFromListJSX}
        </IonList>
      </IonContent>
    </IonPage>
  );
}

const ConvertToPage: React.FC<ConverterProps> = ({ match }) => {

  const history = useHistory();
  if (!ConverterEngine.ConverterItems[match.params.from]) {
    history.replace("/");
    return null;
  }

  const fromItem = ConverterEngine.ConverterItems[match.params.from] ?? null;

  const converterListJSX = Object.entries(ConverterEngine.ConverterItems).map(([key, item]) => {
    // console.log(match);
    return (
      item.short !== fromItem.short &&
      <IonItem routerLink={`/convert/${match.params.from}/${item.short}/`} key={key} >
        {item.title}
      </IonItem >
    );
  }
  );
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Convert from {fromItem.title} to</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList className="layoutBorder" lines="full">
          {converterListJSX}
        </IonList>
      </IonContent>
    </IonPage>
  );
}

const ConverterRouterOutlet: React.FC<ConverterProps> = ({ match }) => (
  <IonPage>
    <IonRouterOutlet>
      <Route exact path="/convert" component={ConvertFromPage} />
      <Route exact path="/convert/:from" component={ConvertToPage} />
      <Route exact path="/convert/:from/:to" component={Index} />
    </IonRouterOutlet>
  </IonPage>
);

export default ConverterRouterOutlet;
