import { IonInput, IonItem } from "@ionic/react";
import TextFieldTypes from "../types/TextFieldTypes";
import classes from "./BasicTextBox.module.css";
import myUtil from "../helpers/cha-utils";

import { IonInputCustomEvent } from "@ionic/core/components";

type Props = {
  controlId: number;
  name?: string;
  type: TextFieldTypes;
  label: string;
  subLabel?: string;
  short: string;
  input: string;
  value: number | string;
  readonly?: boolean;
  labelWidth?: number;
  shortWidth?: number;
  decimalValue?: number;
  textSize?: 1 | 2 | 3 | 4 | 5 | 6;
  onIonInput: (
    controlId: number,
    user_input: string,
    user_value: number
  ) => void;
};
const BasicTextBox: React.FC<Props> = (props) => {
  let textSizeClass = classes.input;
  if (props.textSize) textSizeClass += " " + classes["ts" + props.textSize];

  const decimalValue = props.decimalValue ?? 8;
  const displayValue = myUtil.pfn(+props.value);
  const displayInput = props.input;

  const inputChangeHandler = (event: IonInputCustomEvent<InputEvent>) => {
    const target = event.target;


    if (target.value != null) {
      const user_input = target.value != null ? target.value : 0;
      const user_value = target.value != null ? +target.value : 0;
      props.onIonInput(props.controlId, user_input.toString(), user_value);
    }
  };

  const inputFocusHandler = (
    event: React.FocusEvent<HTMLIonInputElement, Element>
  ) => {
    event.target.value = displayValue;
    event.target.type = "number";
  };

  const inputBlurHandler = (
    event: React.FocusEvent<HTMLIonInputElement, Element>
  ) => {
    event.target.type = "text";
    event.target.value = myUtil.formatNumber(displayValue, decimalValue);
    if (event.target.value.toString() === "")
      props.onIonInput(props.controlId, "", 0);
  };

  return (
    <div className={classes.root}>
      <IonItem lines="none">
        <div
          className={classes.second}
          style={{ width: props.labelWidth + `px` }}
          slot="start"
        >
          <div className={classes["cha-label"]}>{props.label}</div>
          <div className={classes["cha-sub-label"]}>{props.subLabel}</div>
        </div>
        <IonInput
          name={props.name}
          className={`${textSizeClass} ion-no-padding`}
          type={props.type}
          value={displayInput}
          maxlength={10}
          min={0}
          onIonInput={inputChangeHandler}
          onBlur={inputBlurHandler}
          onFocus={inputFocusHandler}
          readonly={props.readonly}
        ></IonInput>
        <div slot="end" className={classes["input-end"]}>
          <div
            className={`${classes.short} ${textSizeClass}`}
            style={{ width: props.shortWidth + `px` }}
          >
            {props.short}
          </div>
        </div>
      </IonItem>
    </div>
  );
};
export default BasicTextBox;