import { RangeValue } from "@ionic/core";
import {
    IonAccordion,
    IonAccordionGroup,
    IonCol,
    IonFooter,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRange,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonToggle,
} from "@ionic/react";
import { chevronUp, colorPaletteOutline, moon, settingsOutline } from "ionicons/icons";
import classes from "./Footer.module.css";
import { useState } from "react";
import myUtil from "../../helpers/cha-utils";

type Props = {
    decimalValue?: number;
    onRangeUpdated: (value: RangeValue) => void;
};


const Footer: React.FC<Props> = (props) => {
    const [darkMode, setDarkMode] = useState<boolean>(
        localStorage.getItem(myUtil.prefix + "dark-mode-status") === "true"
    );

    const [themeColor, setThemeColor] = useState<string>(
        myUtil.formatThemeName(localStorage.getItem(myUtil.prefix + "theme-color-class") ?? "default-theme")
    );

    const themeColorOptionsJSX = Object.entries(myUtil.themeColors).map(([key, item]) => (
        <IonSelectOption key={key} value={key}>{myUtil.formatThemeName(key)}</IonSelectOption>
    ));


    // const themeColors = ["red", "blue", "pink"];
    return (
        <IonFooter>
            <IonAccordionGroup className={classes.iag}>
                <IonAccordion value="settings" toggleIcon={chevronUp} >
                    <IonItem slot="header">
                        <IonIcon
                            icon={settingsOutline}
                            style={{ marginRight: "5px" }}
                        ></IonIcon>
                        <IonLabel>Settings</IonLabel>
                    </IonItem>
                    <div slot="content" className={`${classes.iac} ion-padding`}>
                        <IonGrid fixed={true} >
                            <IonRow className="ion-justify-align-center" >
                                <IonCol >
                                    <IonList lines="none" className={classes.list}>
                                        <IonItem lines="none">
                                            <div>
                                                <IonLabel>Decimal places: <strong>{props.decimalValue}</strong></IonLabel>
                                            </div>

                                            <IonRange
                                                ticks={true}
                                                snaps={true}
                                                min={0}
                                                max={14}
                                                value={props.decimalValue}
                                                onIonKnobMoveEnd={({ detail }) =>
                                                    props.onRangeUpdated(detail.value)
                                                }
                                            >
                                                <IonLabel slot="start">0</IonLabel>
                                                <IonLabel slot="end">14</IonLabel>
                                            </IonRange>
                                        </IonItem>
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonGrid fixed={true} >
                            <IonRow className="ion-justify-align-center splitLayout" >
                                <IonCol size="12" sizeMd="6">
                                    <IonList lines="none" className={classes.list}>
                                        <IonItem>
                                            <IonIcon slot="start" icon={moon}></IonIcon>
                                            <IonLabel>Toggle Dark Mode<div><strong>{darkMode ? "On" : "Off"}</strong></div></IonLabel>
                                            <IonToggle
                                                id="themeToggle"
                                                slot="end"
                                                checked={darkMode}
                                                onIonChange={(e) => {
                                                    myUtil.toggleDarkTheme(e.detail.checked);
                                                    setDarkMode(e.detail.checked);
                                                }}
                                            ></IonToggle>
                                        </IonItem>
                                    </IonList>
                                </IonCol>
                                <IonCol size="12" sizeMd="6">
                                    <IonList lines="none" className={classes.list}>
                                        <IonItem>
                                            <IonIcon slot="start" icon={colorPaletteOutline}></IonIcon>
                                            <IonLabel>Theme color<div><strong>{themeColor}</strong></div></IonLabel>
                                            <IonSelect
                                                placeholder="Select Theme"
                                                className={`${classes["cha-select"]} ion-no-padding`}
                                                title="Select Theme"
                                                interface="popover"
                                                onIonChange={(e) => {
                                                    myUtil.setupTheme(e.detail.value);
                                                    console.log(e.detail.value);
                                                    setThemeColor(myUtil.formatThemeName(e.detail.value));
                                                }}
                                            // compareWith={compareWith}
                                            // value={currentUnit}
                                            // onIonChange={(ev) => setCurrentUnit(ev.detail.value)}
                                            // onIonChange={unitChangeHandler}
                                            >
                                                {themeColorOptionsJSX}
                                            </IonSelect>
                                        </IonItem>
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonGrid>



                    </div>

                </IonAccordion>
            </IonAccordionGroup>
        </IonFooter>
    );
};
export default Footer;
