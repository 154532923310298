import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Sidebar from "../../components/Sidebar";
import { IS_EMBED } from "../../helpers/cha-utils";

const Index: React.FC = () => {
  return (
    <>
      {!IS_EMBED && <Sidebar />}
      <IonPage id="main-content">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Length Conversion Calculator</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <h1>Home</h1>
          <IonButton routerLink="/convert">Length Converter</IonButton>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Index;
