module ConverterEngine {
    export type ConverterItemType = {
        key: string;
        short: string;
        title: string;
        plural?: string;
        formula: number;
    };

    export const ConverterItems: {[key: string]: ConverterItemType} = {
        Å: { key: "angstrom", short: "Å", title: "Angstrom", formula: 1e+10 },

        pm: { key: "picometer", short: "pm", title: "Picometer", formula: 1e+12 },
        nm: { key: "nanometer", short: "nm", title: "Nanometer", formula: 1e+9 },
        μm: { key: "micrometer", short: "μm", title: "Micron", formula: 1e+6 },
        mm: { key: "millimeter", short: "mm", title: "Millimeter", formula: 1e+3 },
        cm: { key: "centimeter", short: "cm", title: "Centimeter", formula: 1e+2 },
        dm: { key: "decimeter", short: "dm", title: "Decimeter", formula: 1e+1 },
        m: { key: "meter", short: "m", title: "Meter", formula: 1 },
        dkm: { key: "dekameter", short: "dkm", title: "Dekameter", formula: 1e-1 },
        hm: { key: "hectometer", short: "hm", title: "Hectometer", formula: 1e-2 },
        km: { key: "kilometer", short: "km", title: "Kilometer", formula: 1e-3 },

        in: { key: "inch", short: "in", title: "Inch", formula: 39.37007874 },
        ft: { key: "foot", short: "ft", title: "Foot", formula: 3.2808399 },
        yd: { key: "yard", short: "yd", title: "Yard", formula: 1.0936133 },

        mi: { key: "mile", short: "mi", title: "Mile", formula: 0.00062137119 },
        ly: { key: "lightyear", short: "ly", title: "Lightyear", formula: 1/9.46728E+15 },
    };
} 

export default ConverterEngine;
